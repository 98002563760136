<template>
  <div>
    <p>
      {{ lang[activeLang]['The ACLU-NC only handles matters that arise in the Northern California region, stretching north to the Oregon border and south to Monterey and Inyo counties. If your issue arises elsewhere, including in Idaho,'] }} <a
        href="https://www.aclu.org/affiliates"
        target="_blank"
      >{{ lang[activeLang]['find your local ACLU office'] }}</a>.
    </p>
  </div>
</template>

<script>

export default {
  name: 'Coverage',
  props: {
    activeLang: {
      type: String,
      default: 'English',
    },
  },
  data() {
    return {
      show: false,
    }
  },
}
</script>


<style scoped>

p {
  font-size: 18px;
  margin: 0 1em;
}

</style>

